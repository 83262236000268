<template>
	<div>
		<CForm>
			<CRow>
				<CCol sm="12">
					<CCard>
						<CCardHeader>
							<strong>{{ $t('pages.account.addNewUser') }}</strong>
						</CCardHeader>
						<CCardBody>
							<CForm>
								<CSelect horizontal :options="roleOptions" :value.sync="values.role"
										 :isValid="!errors.role" :invalidFeedback="errors.role" @change="onRoleChanged">
									<template #label>
										<div class="col-sm-3 col-form-label">
											Cấp bậc
											<small class="requiredInput">(*)</small>
										</div>
									</template>
								</CSelect>

								<div role="group" class="form-group form-row">
									<label class="col-sm-3 col-form-label">Cấp trên</label>
									<div class="col-sm-9 input-group" v-if="selectedUpperAccount != null">
										<div class="d-flex w-100 justify-content-between">
											<h5 class="mb-1">{{selectedUpperAccount.userName}} - {{selectedUpperAccount.fullName}}</h5>
											<a href="javascript: void(0)" @click="()=> this.isSelectingUpper = true">
												<h6 v-if="values.role=='agent'">Thay đổi</h6>
											</a>
										</div>
										<small>{{selectedUpperAccount.role.displayName}}</small>
									</div>
									<div class="col-sm-9 input-group" v-else>
										<div class="d-flex w-100 justify-content-between">
											<h5 class="mb-1">N/A</h5>
											<a href="javascript: void(0)" @click="()=> this.isSelectingUpper = true">
												<h6 v-if="canSelectUpper">Chọn cấp trên</h6>
											</a>
										</div>

										<div class="invalid-feedback" :style="[!!errors.parentId ? {display: 'block'}: {display: 'none'}]">
											{{errors.parentId}}
										</div>
									</div>
								</div>

								<div class="form-group form-row" v-if="showOperatorList">
									<div class="col-sm-3 col-form-label"></div>
									<div class="col-sm-9 input-group">
										<CRow style="width:100%">
											<CCol sm="12">
												<CCard class="card-secondary card-outline">
													<CCardHeader>
														<div style="display:flex;flex-direction: row;justify-content: space-between;">
															<h5>Danh sách cấp trên</h5>
															<a href="javascript:void(0)" @click="()=>{this.isSelectingUpper = false;}"><h6>Đóng lại</h6></a>
														</div>

													</CCardHeader>
													<CCardBody>
														<div v-if="upperAccounts !=null && upperAccounts[this.values.role] != null">
															<SearchableSaleDropdown :userArray="removeDiacritics(upperAccounts[this.values.role])" @itemSelected="onSelectOperator"></SearchableSaleDropdown>
														</div>
														
														<!-- <CListGroup v-if="upperAccounts !=null && upperAccounts[this.values.role] != null">
															<CListGroupItem component="a" href="javascript:void(0)" v-for="item in upperAccounts[this.values.role]" :key="item.id">
																<div class="d-flex w-100 justify-content-between">
																	<h5 class="mb-1">{{item.userName}}</h5>
																	<a href="javascript: void(0)" @click="onSelectOperator(item)">
																		<h6>Chọn</h6>
																	</a>

																</div>
																<p class="mb-1">{{item.fullName}}</p>
																<small>{{item.role.displayName}}</small>
															</CListGroupItem>
														</CListGroup> -->
													</CCardBody>
												</CCard>
											</CCol>
										</CRow>

									</div>
								</div>

								<CInput :placeholder="$t('pages.account.enterUserName')" v-model="values.userName" horizontal
										:isValid="!errors.userName" :invalidFeedback="errors.userName">
									<template #label>
										<div class="col-sm-3 col-form-label">
											{{ $t('common.userName') }}
											<small class="requiredInput">(*)</small>
										</div>
									</template>
								</CInput>

								<div role="group" class="form-group form-row">
									<label class="col-sm-3 col-form-label"></label>
									<div class="col-sm-9 input-group">
										(Tên đăng nhập phải sử dụng tên không dấu và khoảng trắng)
									</div>
								</div>

								<CInput type="password" :placeholder="$t('pages.account.enterPassword')"
										v-model="values.password" horizontal
										:isValid="!errors.password" :invalidFeedback="errors.password">
									<template #label>
										<div class="col-sm-3 col-form-label">
											{{ $t('pages.account.password') }}
											<small class="requiredInput">(*)</small>
										</div>
									</template>
								</CInput>

								<CInput type="password" :placeholder="$t('pages.account.enterRetypePassword')"
										v-model="values.passwordConfirmation" horizontal
										:isValid="!errors.passwordConfirmation" :invalidFeedback="errors.passwordConfirmation">
									<template #label>
										<div class="col-sm-3 col-form-label">
											{{ $t('pages.account.passwordConfirmation') }}
											<small class="requiredInput">(*)</small>
										</div>
									</template>
								</CInput>

								<CInput :placeholder="$t('pages.account.fullName')" v-model="values.fullName" horizontal
										:isValid="!errors.fullName" :invalidFeedback="errors.fullName">
									<template #label>
										<div class="col-sm-3 col-form-label">
											{{ $t('pages.account.fullName') }}
											<small class="requiredInput">(*)</small>
										</div>
									</template>
								</CInput>

								<CInput :placeholder="$t('common.enterEmail')" v-model="values.email"
										horizontal
										:isValid="!errors.email" :invalidFeedback="errors.email">
									<template #label>
										<div class="col-sm-3 col-form-label">
											{{ $t('common.email') }}
											<small class="requiredInput">(*)</small>
										</div>
									</template>
								</CInput>

								<CInput :placeholder="$t('common.enterPhoneNumber')" v-model="values.phoneNumber" horizontal maxlength=12
										:isValid="!errors.phoneNumber" :invalidFeedback="errors.phoneNumber">
									<template #label>
										<div class="col-sm-3 col-form-label">
											{{ $t('common.phone') }}
											<small class="requiredInput">(*)</small>
										</div>
									</template>
								</CInput>

								<CInput :placeholder="$t('pages.account.enterAddress')" v-model="values.address" horizontal
										:isValid="!errors.address" :invalidFeedback="errors.address">
									<template #label>
										<div class="col-sm-3 col-form-label">
											{{ $t('common.address') }}
											<small class="requiredInput">(*)</small>
										</div>
									</template>
								</CInput>

								<div class="form-group form-row" v-if="isShowOptionCreateAgentAutomation">
									<div class="col-sm-3 col-form-label">
										<label class="col-form-label">Tự động thêm đại lý</label>
									</div>
									<div class="col-sm-9 input-group">
										<CSwitch :key="values.isCreateAgentAutomation" color="info" :checked.sync="values.isCreateAgentAutomation" @click="changeCreateAgentAutomation($event.value)" size="sm" />
									</div>
								</div>
								<div v-if="values.isCreateAgentAutomation">
									<CInput :placeholder="$t('pages.agent.enterAgentName')" v-model="values.agentName" horizontal
											:isValid="!errors.agentName" :invalidFeedback="errors.agentName">
										<template #label>
											<div class="col-sm-3 col-form-label">
												{{ $t('pages.agent.agentName') }}
												<small class="requiredInput">(*)</small>
											</div>
										</template>
									</CInput>

									<CSelect horizontal :options="agentProvinceOptions" :value.sync="values.agentProvinceId"
											 :isValid="!errors.agentProvinceId" :invalidFeedback="errors.agentProvinceId" @change="onChangeProvince">
										<template #label>
											<div class="col-sm-3 col-form-label">
												{{ $t('pages.agent.selectProvince') }}
												<small class="requiredInput">(*)</small>
											</div>
										</template>
									</CSelect>

									<CSelect horizontal :options="agentDistrictOptions" :value.sync="values.agentDistrictId"
											 :isValid="!errors.agentDistrictId" :invalidFeedback="errors.agentDistrictId" @change="onChangeDistrict">
										<template #label>
											<div class="col-sm-3 col-form-label">
												{{ $t('pages.agent.selectDistrict') }}
												<small class="requiredInput">(*)</small>
											</div>
										</template>
									</CSelect>

									<CSelect horizontal :options="agentWardOptions" :value.sync="values.agentWardId"
											 :isValid="!errors.agentWardId" :invalidFeedback="errors.agentWardId">
										<template #label>
											<div class="col-sm-3 col-form-label">
												{{ $t('pages.agent.selectWard') }}
												<small class="requiredInput">(*)</small>
											</div>
										</template>
									</CSelect>
									
								</div>
								<div class="form-actions">
									<CButton type="button" color="primary" @click="onSaveNewAccount">{{ $t('common.save') }}</CButton>
									<CButton type="button" color="secondary" @click="onCancel">{{ $t('common.cancel') }}</CButton>
								</div>
							</CForm>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</CForm>
	</div>
</template>

<script>
	import SearchableSaleDropdown from '@/components/SearchableSaleDropdown.vue';
	import {stringToASCII} from '@/helpers/func'
    import i18n from '@/plugins/i18n'
	import { mapGetters, mapState, mapActions } from 'vuex'
	

	import _ from 'lodash'
	import * as yup from 'yup'
	const usernameExp = /^[0-9aA-zZ.@_\\-]+$/;

	const userInfoSchema = yup.object().shape({
		userName: yup.string().required("Nhập tên đăng nhập").matches(usernameExp, 'Tên đăng nhập phải sử dụng tên không dấu và khoảng trắng'),
		role: yup.string().required("Nhập cấp bậc"),
		parentId: yup.number().required().moreThan(1, 'Bạn hãy chọn cấp cha'),
		email: yup.string().required("Nhập email").email('Email không hợp lệ'),
		fullName: yup.string().required("Nhập họ và tên đầy đủ"),
		phoneNumber: yup.string().required("Nhập số điện thoại"),
		address: yup.string().required("Nhập địa chỉ"),
		password: yup.string().required('Nhập mật khẩu'),
		passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Mật khẩu không khớp'),		
        agentName: yup.string().when('isCreateAgentAutomation', {
			is: true,
            then: yup.string().required("Nhập tên đại lý")
		}),
        agentProvinceId: yup.string().when('isCreateAgentAutomation', {
            is: true,
            then: yup.string().required(i18n.t('pages.agent.selectProvince'))
		}),
        agentDistrictId: yup.string().when('isCreateAgentAutomation', {
            is: true,
            then: yup.string().required(i18n.t('pages.agent.selectDistrict'))
		}),
        agentWardId: yup.string().when('isCreateAgentAutomation', {
            is: true,
            then: yup.string().required(i18n.t('pages.agent.selectWard'))
        }),
    });

	const initialErrors = {
		userName: '',
		role: '',
		email: '',
		fullName: '',
		phoneNumber: '',
		address: '',
		password: '',
		passwordConfirmation: '',
		agentName: '',
		agentProvinceId: '',
		agentDistrictId: '',
        agentWardId: '',
	};

	export default {
		name: 'Tables',
		data() {
			return {
				horizontal: { label:'col-3', input:'col-9' },	
				invalidInput: false,
				errorInput: '',
				salesmanModel: null,
                salesmanList: [],

				values: {
					userName: '',
					role: '',
					parentId: 0,
					email: '',
					fullName: '',
					phoneNumber: '',
					address: '',
					password: '',
					passwordConfirmation: '',
					agentName: '',
                    isCreateAgentAutomation: false,
                    agentProvinceId: '',
                    agentDistrictId: '',
                    agentWardId: '',
				},
				errors: {
					userName: '',
					role: '',
					parentId: '',
					email: '',
					fullName: '',
					phoneNumber: '',
					address: '',
					password: '',
					passwordConfirmation: '',
					agentName: '',
                    agentProvinceId: '',
                    agentDistrictId: '',
                    agentWardId: '',
				},
				selectedUpperAccount: null,
				upperAccounts: {},
				isSelectingUpper: false,
				currentProfile: null,
                isShowOptionCreateAgentAutomation: false,
				//isCreateAgentAutomation: false,				
			}
		},
  components: {
			SearchableSaleDropdown
        },
        computed: {
			...mapState('role', ['roles']),
			...mapGetters('auth', ['user']), 
            ...mapGetters('location', ['listProvinces', 'listDistricts', 'listWards']),
			roleOptions(){
				let options = [];
				let filteredRoles = [];
				if(this.user!=null && this.user.role != '' && this.roles != null)
				{
					var currentRole = this.roles.find(el => el.name == this.user.role);
					if(currentRole != null)
					{
						if(currentRole.name !== 'admin'){
							filteredRoles = this.roles
							.filter(el => el.name != 'admin' && el.name != 'user')
							.filter(el => el.level > currentRole.level && el.level <= currentRole.level + 1)
							console.log('filtered roles', filteredRoles);
						} else{
							filteredRoles = this.roles
							.filter(el => el.name != 'admin' && el.name != 'user');
						}

					}
				}
				options = filteredRoles.map(item => {
						return {value: item.name, label: item.displayName};
					});

				options.splice(0,0,
					{
						value: '', 
						label: '---Chọn cấp bậc---'
					});

				return options;
			},
			agentProvinceOptions() {
				let options = [];
				if (this.listProvinces != null && this.listProvinces.length > 0) {
                    options = this.listProvinces.map(item => {
                        return { value: item.provinceId, label: item.name };
                    });

                    options.splice(0, 0,
                        {
                            value: '',
                            label: i18n.t('pages.agent.selectProvince')
                        });
				}

                return options;
			},
            agentDistrictOptions() {
                let options = [];
                if (this.listDistricts != null && this.listDistricts.length > 0) {
                    options = this.listDistricts.map(item => {
                        return { value: item.districtId, label: item.name };
                    });

                    options.splice(0, 0,
                        {
                            value: '',
                            label: i18n.t('pages.agent.selectDistrict')
                        });
                }

                return options;
			},
            agentWardOptions() {
				let options = [];
                if (this.listWards != null && this.listWards.length > 0) {
                    options = this.listWards.map(item => {
                        return { value: item.wardId, label: item.name };
                    });

                    options.splice(0, 0,
                        {
                            value: '',
                            label: i18n.t('pages.agent.selectWard')
                        });
				}

                return options;
			},
			showOperatorList(){
				return this.isSelectingUpper == true && this.user != null && this.user.role == 'admin';
			},
			canSelectUpper(){
				return this.user != null && this.user.role == 'admin' && this.values.role != 'admin' && this.values.role != 'sale' && this.values.role != '';
			},			
        },  
		methods: {
			...mapActions('role', ['getAllRoles']),            
			isEmpty(val) {
				return _.isEmpty(val);
			},
			validate(field) {
                userInfoSchema.validateAt(field, this.values)
                    .then(() => this.errors[field] = '')
                    .catch((err) => {
                        this.errors[err.path] = err.message
                        console.log('validate error', err);
                    });
            },

			async validateAll() {
				console.log("CONTEXT VALIDATE ALL",this) 
				try {
					this.errors = {...initialErrors};
                    await userInfoSchema.validate(this.values, { abortEarly: false });
					return true;
                }
                catch (err) {
                    _.forEach(err.inner, error => {
                        this.errors[error.path] = error.message;
                    })
					console.log("all errors", this.errors)
                    return false;
                }
			},


            validator(val) {
                return val ? val.length > 0 : false
            },

			removeDiacritics(userList) {
				if(userList == null)
					return null;
				userList.map(u => u.nameWithoutDiacritics = stringToASCII(u.fullName).toLowerCase())
				return userList;
			},
			async onRoleChanged(evt) {
				let newRole = evt.target.value;
				var selectedRole = this.roles.find(r => r.name == evt.target.value);

				// @Ha
                this.values.isCreateAgentAutomation = false;
				if (this.user.role === 'sale' || (selectedRole != null && selectedRole.name === 'agent')) {					
                    this.isShowOptionCreateAgentAutomation = true;
				}
				else {                   
                    this.isShowOptionCreateAgentAutomation = false;
				}

				console.log('selectedRole', selectedRole);
				if(selectedRole == null) return;
				let upperRole = this.roles.find(r => r.level == selectedRole.level - 1);
				if(upperRole == null) return;
				if(this.user.role === 'admin')
				{
					if(selectedRole.name == 'sale')
					{
						console.log('current profile', this.currentProfile)
						this.onSelectOperator(this.currentProfile);				
					}
						
					else
						this.onSelectOperator(null);				
				}
					
				console.log('upperRole', upperRole);
				var data = await this.$accountService.getUsersByRole({ roleName: upperRole.name });
				if(data.success)
				{
					this.upperAccounts[selectedRole.name] = data.data;
				}
			},

			onSelectOperator(acc){
				this.selectedUpperAccount = acc;
				if(acc != null && acc.id > 0)
					this.values.parentId = acc.id;
				else
					this.values.parentId = 0;
				this.isSelectingUpper = false;
			},
			
			async onSaveNewAccount() {			
				var isValid = await this.validateAll();
                
				if(!isValid)
					return;               			

				var response = await this.$accountService.addAccount({
                    userName: this.values.userName,
                    fullName: this.values.fullName,
                    email: this.values.email,
                    phoneNumber: this.values.phoneNumber,
                    address: this.values.address,
                    password: this.values.password,
                    retypePassword: this.values.passwordConfirmation,
                    role: this.values.role,
					parentId: this.values.parentId,
					agentName: this.values.agentName,
                    autoCreateAgent: this.values.isCreateAgentAutomation,
					agentProvinceId: this.values.agentProvinceId,
                    agentDistrictId: this.values.agentDistrictId,
                    agentWardId: this.values.agentWardId,
                });
              
				if (response.data == true) {
					this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.account.noticeAddSuccessful') }, { root: true });
					this.$router.push('/users/list/');			
					return;
				}
				
				if (response != null && response.data == false && response.errorMessage != '') {
					let mess = response.errorMessage;
					if (mess == 'InvalidDataInput')
						mess = i18n.t('pages.account.invalidDataInput');
					else if (mess == 'EmailOrUsernameAlready')
						mess = i18n.t('pages.account.emailOrUsernameAlready');
					else if (mess === "InvalidAgentName")
						mess = i18n.t('pages.agent.agentNameisInvalid');
                    else if (mess === "AgentExisted")
                        mess = i18n.t('pages.agent.agentNameExisted');	
					else 
						mess = i18n.t('pages.account.noticeAddAccountFailed'); 
					this.$store.dispatch('alert/error', mess, { root: true });
				}
			},

			onCancel(){
					this.$router.go(-1);
			},	
            async onChangeProvince(event) {
                await this.$store.dispatch("location/getDistrictsByProviceId", event.target.value);
			},
            async onChangeDistrict(event) {
                await this.$store.dispatch("location/getWardsByDistrictId", event.target.value);
            }, 
		},
		
		async mounted() {			
			var response = await this.$accountService.getAccountProfileById(this.user.id);
			if(response.success)
			{
				this.currentProfile = response.data;
				this.onSelectOperator(this.currentProfile);
			}
			// if(this.user.role != 'admin' && response.success)
			// {
				
			// 	this.onSelectOperator(this.currentProfile);
			// }
				
			this.$store.dispatch("account/initNewAccount");
			if(this.roles == null || this.roles.length == 0) {
				await this.getAllRoles();
			}

            this.$store.dispatch("location/getAllProvinces");        
		}
	}
</script>

