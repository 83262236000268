export function isNullOrWhiteSpaces(str) {
    return str === null || str.match(/^ *$/) !== null;
}

export function replaceWhiteSpaces(str) {
    return str.replace(/\s\s+/g, ' ');
}
export function stringToASCII(str) {
    try {
        return str.replace(/[àáảãạâầấẩẫậăằắẳẵặ]/ig, 'a')
            .replace(/[èéẻẽẹêềếểễệ]/ig, 'e')
            .replace(/[đ]/ig, 'd')
            .replace(/[ìíỉĩị]/ig, 'i')
            .replace(/[òóỏõọôồốổỗộơờớởỡợ]/ig, 'o')
            .replace(/[ùúủũụưừứửữự]/ig, 'u')
            .replace(/[ỳýỷỹỵ]/ig, 'y')
    } catch {
        return ''
    }
}