<template>
    <section class="dropdown-wrapper">
		<div @click="isVisible = !isVisible" class="selected-item">
			<span v-if="selectedItem != null">{{selectedItem.fullName}}</span>
			<span v-else> Chọn 1 tài khoản sale...</span>

			<svg
			:class="isVisible ? 'dropdown': ''"
			 class="drop-down-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z"/></svg>
		</div>
		<div :class="isVisible ? 'visible': 'invisible'" class="dropdown-popover">
			<input v-model="searchQuery" type="text" placeholder="Nhập tên tìm kiếm">
			<span v-if="filteredUsers.length===0">No Data Available</span>
			<div class="options">
				<ul>
					<li
					@click="selectItem(user)"
					 v-for="(user) in filteredUsers" :key="user.id">
					 <span class="name-field">
					 {{user.fullName}}
					 </span>
					 <br v-if="user.address != null"/>
					 <span v-if="user.address != null" class="address-field">
						{{user.address}}
					 </span>
					 </li>
				</ul>
			</div>
		</div>
	</section>
</template>

<script>   

import {stringToASCII} from '@/helpers/func'

    export default {
        name: 'SearchableSaleDropdown',
        data() {
            return {
				searchQuery: '',
				selectedItem: null,
				isVisible: false,
            };
        },
		props: {
			userArray: {
				type:Array,
				default: () => []
			},        
		},
        components: {
        },
		computed: {
			filteredUsers(){
				const query = stringToASCII(this.searchQuery.toLowerCase());
				if(this.searchQuery === '')
					return this.userArray;
				return this.userArray.filter((user) =>{
					return Object.values(user).some(word => 
					String(word).toLowerCase().includes(query))
				});
			}
		},
        methods: {
            selectItem(user) {
				this.selectedItem = user;
				this.isVisible = false;
				this.$emit('itemSelected', this.selectedItem)
			}
        }
    }
</script>
<style scoped lang="scss">
.dropdown-wrapper {
	width: 100%;
	min-width: 250px;
	position: relative;
	margin: 0 auto;

	.selected-item {
	height: 40px;
	border: 2px solid lightgrey;
	border-radius: 5px;
	padding: 5px 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 16px;
	font-weight: 400;
	
	.drop-down-icon{
		transform: rotate(0deg);
		transition: all 0.3s ease;
		&.dropdown{
		transform: rotate(180deg);
	}
	}
}
}



.dropdown-popover {
	position: absolute;
	z-index: 10000;
	border: 2px solid lightgrey;
	top: 46px;
	left:0;
	right: 0;
	background-color: #fff;
	width: 100%;
	padding: 10px;
	visibility: hidden;
	transition: all 0.3s linear;
	max-height:0px;
	overflow: hidden;

	&.visible {
		max-height: 450px;
		visibility: visible;
	}
	input {
		width: 100%;
		height: 30px;
		border: 2px solid lightgray;
		font-size: 16px;
		padding-left: 8px;
	}

	.options {
		width: 100%;
		
		ul {
			list-style: none;
			text-align: left;
			padding-left: 0;
			max-height: 200px;
			overflow-y: scroll;
			overflow-x: hidden;
			
			li {
				width: 100%;
				border-bottom: 1px solid lightgray;
				padding: 10px;
				background-color: #f1f1f1;
				cursor: pointer;
				&:hover {
					background: #70878a;
					color:#fff;
					font-weight: bold;
				}
			}
		}
	}
	.name-field 
	{
		font-weight: bold;
	}
	.address-field {
		padding-left: 5px;
		font-style: italic;
	}
}
</style>
